@import '@bryntum/scheduler/scheduler.material.css';

.tooltip-table,
.tooltip-cell {
    border: 1px solid black;
    border-collapse: collapse;
    width: '100%';
}

.tooltip-cell {
    max-width: 800px;
    padding: 4px;
}

.b-icon.b-icon-edit.custom-edit-icon {
    font-size: 1rem;
    color: white;
}

.b-popup .b-popup-close {
    font-size: 1rem;
    color: white;
}

.b-popup.b-sch-event-tooltip {
    max-width: 100%;
}

.driver {
    background-color: #a3271f;
}

.broker {
    background-color: #285c63;
}

#main {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-grow: 1;

    .b-react-scheduler-container {
        flex: 1 1 0;
        overflow: hidden;
    }

    .b-sch-event {
        border-radius: 10px;
    }

    .b-sch-event-selected {
        border: 4px dotted #000;
    }

    .column {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    #unplannedContainer {
        display: flex;
        flex-direction: column;

        .b-group-state-icon {
            color: #424242 !important;
        }

        .b-gridbase {
            flex: none;

            .b-grid-cell {
                cursor: pointer;
            }

            i {
                margin-inline-end: 10px;
                color: #4887e5;
            }
        }
    }
}